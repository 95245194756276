import styles from "./Home.module.scss";
import Video from "../../assets/vid/clean.mp4";
import Button from "../../components/button/Button";
import Contact from "../../components/contact/Contact";
import { Link } from "react-router-dom";
import React, { useRef } from "react";
import Leystung from "../../components/leystung/Leystung";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Helmet } from "react-helmet-async";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import Dachrinnenreinigung from "../../assets/img/Dachrinnenreinigung.png";
import Gartenarbeiten from "../../assets/img/Gartenarbeiten.png";
import Gebäudereinigung from "../../assets/img/Gebäudereinigung.png";
import Hausmeisterdienst from "../../assets/img/Hausmeisterdienst.png";
import Photovoltaikreinigung from "../../assets/img/Photovoltaikreinigung.png";
import RohrreinigungImg from "../../assets/img/Rohrreinigung.png";
import Rohrreinigung from "../../components/rohrreinigung/Rohrreinigung";

function Home() {
  const leistungenRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToSection = (ref) => {
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 50);
  };

  //const scrollToTop = () => {
  //  setTimeout(() => {
  //    window.scrollTo({ top: 0, behavior: "smooth" });
  //  }, 50);
  // };

  return (
    <div className={styles.Home}>
      <Helmet>
        <title>
          Ipekten Dienstleistung | Gebäudereinigung & Rohrreinigung in Hemmingen
        </title>
        <meta
          name="description"
          content="Professionelle Gebäudereinigung & Rohrreinigung in Hemmingen und Umgebung. Ipekten Dienstleistungen sorgt für Sauberkeit, Effizienz und Qualität."
        />
        <meta
          name="keywords"
          content="Gebäudereinigung, Rohrreinigung, Dachrinnenreinigung, Gartenarbeiten, Hausmeisterdienst, Photovoltaikreinigung, Hemmingen, Ludwigsburg, Stuttgart, Schöckingen, Schwieberdingen, Möglingen"
        />
        <meta property="og:title" content="Ipekten Dienstleistung" />
        <meta
          property="og:description"
          content="Professionelle Gebäudereinigung & Rohrreinigung in Hemmingen und Umgebung. Jetzt kontaktieren für ein individuelles Angebot!"
        />
        <meta property="og:type" content="website" />

        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Ipekten Dienstleistung",
        "@id": "https://ipekten.de/",
        "url": "https://ipekten.de/",
        "telephone": "+49 172 31 17 110",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Schellingstr. 17",
          "addressLocality": "Hemmingen",
          "postalCode": "71282",
          "addressCountry": "DE"
        },
        "priceRange": "$$",
        "description": "Ipekten Dienstleistungen bietet Gebäudereinigung, Rohrreinigung und weitere Services in Hemmingen und Umgebung an.",
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "48.863161",
          "longitude": "9.066462"
        },
        "openingHoursSpecification": [
          {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday"
            ],
            "opens": "00:00",
            "closes": "23:59"
          }
        ],
        "sameAs": [
          "https://www.instagram.com/ipekten_dienstleistung"
        ]
      }
    `}
        </script>
      </Helmet>
      <div className={styles.video__overlay}>
        <div className={styles.section__container}>
          <div className={styles.title__container}>
            <h1>
              Willkommen bei <br /> Ipekten Dienstleistung
            </h1>
            <span>Ihrem Partner für professionelle Gebäudereinigung!</span>
          </div>
          <div className={styles.button__container}>
            <Link
              className={styles.link__container}
              onClick={() => scrollToSection(leistungenRef)}
            >
              <Button variant="primary" label="Leistungen" />
            </Link>
            <Link
              className={styles.link__container}
              onClick={() => scrollToSection(contactRef)}
            >
              <Button variant="secondary" label="Kontakt" />
            </Link>
          </div>
        </div>
      </div>
      <video autoPlay loop muted playsInline>
        <source src={Video} type="video/mp4" />
      </video>
      <div className={styles.section1}>
        <h1 className={styles.sectionh1}>
          <strong>
            IHR REGIONALER ROHRREINIGER
            <br />
            SORGT SCHNELL FÜR FREIEN FLUSS.
          </strong>
        </h1>
        <h1 className={styles.sectionh1}>
          UNSER EIGENES UNTERNEHMEN IST NEU,
          <br />
          UNSERE ERFAHRUNG 20 JAHRE ALT.
        </h1>
        <h2>Sauberkeit. Effizienz. Qualität.</h2>
        <span className={styles.section1span}>
          Herzlich willkommen bei Ipekten Dienstleistungen, Ihrem verlässlichen
          Partner für umfassende Gebäudereinigungsdienstleistungen. Wir
          verstehen, dass saubere Räumlichkeiten nicht nur ein ästhetisches
          Anliegen sind, sondern auch die Gesundheit, Sicherheit und
          Produktivität Ihrer Mitarbeiter fördern. Mit unserem breiten Spektrum
          an Dienstleistungen sind wir darauf spezialisiert, Ihre
          Reinigungsanforderungen mit höchster Professionalität zu erfüllen.
        </span>
        <div className={styles.leistungen} ref={leistungenRef}>
          <h1>Unsere Dienstleistungen</h1>
          <div className={styles.leystung__container}>
            <Rohrreinigung
              img={RohrreinigungImg}
              title="Rohrreinigung"
              description="Verstopfte Rohre können zu ernsthaften Problemen führen. Unsere
              Rohrreinigungsdienste bieten schnelle und effektive Lösungen.
              Wir verwenden moderne Technologien, um Verstopfungen zu
              beseitigen und den reibungslosen Betrieb Ihrer sanitären Anlagen
              sicherzustellen. Vertrauen Sie auf unsere Erfahrung, wenn es um
              die Wartung und Reinigung Ihrer Rohrsysteme geht."
            />
          </div>
          <Swiper
            modules={[Navigation]}
            slidesPerView={1}
            pagination={{ clickable: true }}
            spaceBetween={30}
            loop={true}
            navigation={true}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
            }}
            className={styles.leystungSwiper}
          >
            <SwiperSlide>
              <Leystung
                img={RohrreinigungImg}
                title="Rohrreinigung"
                description="Verstopfte Rohre können zu ernsthaften Problemen führen. Unsere
              Rohrreinigungsdienste bieten schnelle und effektive Lösungen.
              Wir verwenden moderne Technologien, um Verstopfungen zu
              beseitigen und den reibungslosen Betrieb Ihrer sanitären Anlagen
              sicherzustellen. Vertrauen Sie auf unsere Erfahrung, wenn es um
              die Wartung und Reinigung Ihrer Rohrsysteme geht."
              />
            </SwiperSlide>
            <SwiperSlide>
              <Leystung
                img={Gebäudereinigung}
                title="Gebäudereinigung"
                description="Unsere Gebäudereinigungsdienste erstrecken sich über alle
              Fachbereiche, um sicherzustellen, dass Ihr Gebäude in jedem
              Winkel makellos ist. Unsere hochqualifizierten
              Reinigungsfachleute verwenden fortschrittliche Techniken, um
              Böden, Oberflächen, Fenster und mehr gründlich zu reinigen. Egal
              ob Bürogebäude, Einzelhandelsfläche oder Wohnanlage - wir passen
              unsere Dienstleistungen an Ihre spezifischen Anforderungen an."
              />
            </SwiperSlide>
            <SwiperSlide>
              <Leystung
                img={Dachrinnenreinigung}
                title="Dachrinnenreinigung"
                description="Eine regelmäßige Dachrinnenreinigung ist entscheidend, um
              Schäden an Ihrem Gebäude zu verhindern. Unser Service umfasst
              nicht nur die Reinigung von Dachrinnen, sondern auch die
              Fassadenreinigung. Wir entfernen Schmutz, Algen und
              Ablagerungen, um Ihr Gebäude nicht nur zu schützen, sondern auch
              sein ästhetisches Erscheinungsbild zu verbessern."
              />
            </SwiperSlide>
            <SwiperSlide>
              <Leystung
                img={Gartenarbeiten}
                title="Gartenarbeiten"
                description="Unsere Gartenarbeiten umfassen alles, was Ihr grünes Paradies
                benötigt. Von der Rasenpflege über Hecken- und Baumschnitt bis
                hin zur Unkrautbekämpfung kümmern wir uns um alle Aspekte Ihres
                Gartens. Genießen Sie eine gepflegte Außenanlage, ohne sich um
                den Aufwand kümmern zu müssen."
              />
            </SwiperSlide>
            <SwiperSlide>
              <Leystung
                img={Hausmeisterdienst}
                title="Hausmeisterdienst"
                description="Unser Hausmeisterdienst bietet umfassende Betreuung für Ihr
                Gebäude. Von der regelmäßigen Wartung über kleinere Reparaturen
                bis hin zur Überwachung der Sicherheitseinrichtungen – wir
                kümmern uns darum. Unser Ziel ist es, sicherzustellen, dass Ihr
                Gebäude immer in bestem Zustand ist und jederzeit ein angenehmes
                und sicheres Umfeld bietet."
              />
            </SwiperSlide>
            <SwiperSlide>
              <Leystung
                img={Photovoltaikreinigung}
                title="Photovoltaikreinigung"
                description="Die Effizienz Ihrer Photovoltaikanlage hängt von ihrer
                Sauberkeit ab. Unsere Photovoltaikreinigungsdienste sorgen
                dafür, dass Ihre Solarpaneele von Schmutz und Ablagerungen
                befreit werden. Damit gewährleisten wir, dass Ihre Anlage
                optimale Leistung erbringt und Sie das volle Potenzial Ihrer
                erneuerbaren Energiequelle nutzen können."
              />
            </SwiperSlide>
          </Swiper>
          <div className={styles.leystung__container}>
            <Leystung
              img={Gebäudereinigung}
              title="Gebäudereinigung"
              description="Unsere Gebäudereinigungsdienste erstrecken sich über alle
              Fachbereiche, um sicherzustellen, dass Ihr Gebäude in jedem
              Winkel makellos ist. Unsere hochqualifizierten
              Reinigungsfachleute verwenden fortschrittliche Techniken, um
              Böden, Oberflächen, Fenster und mehr gründlich zu reinigen. Egal
              ob Bürogebäude, Einzelhandelsfläche oder Wohnanlage - wir passen
              unsere Dienstleistungen an Ihre spezifischen Anforderungen an."
            />
            <Leystung
              img={Dachrinnenreinigung}
              title="Dachrinnenreinigung"
              description="Eine regelmäßige Dachrinnenreinigung ist entscheidend, um
              Schäden an Ihrem Gebäude zu verhindern. Unser Service umfasst
              nicht nur die Reinigung von Dachrinnen, sondern auch die
              Fassadenreinigung. Wir entfernen Schmutz, Algen und
              Ablagerungen, um Ihr Gebäude nicht nur zu schützen, sondern auch
              sein ästhetisches Erscheinungsbild zu verbessern."
            />
          </div>
          <div className={styles.leystung__container}>
            <Leystung
              img={Gartenarbeiten}
              title="Gartenarbeiten"
              description="Unsere Gartenarbeiten umfassen alles, was Ihr grünes Paradies
              benötigt. Von der Rasenpflege über Hecken- und Baumschnitt bis
              hin zur Unkrautbekämpfung kümmern wir uns um alle Aspekte Ihres
              Gartens. Genießen Sie eine gepflegte Außenanlage, ohne sich um
              den Aufwand kümmern zu müssen."
            />
            <Leystung
              img={Hausmeisterdienst}
              title="Hausmeisterdienst"
              description="Unser Hausmeisterdienst bietet umfassende Betreuung für Ihr
              Gebäude. Von der regelmäßigen Wartung über kleinere Reparaturen
              bis hin zur Überwachung der Sicherheitseinrichtungen – wir
              kümmern uns darum. Unser Ziel ist es, sicherzustellen, dass Ihr
              Gebäude immer in bestem Zustand ist und jederzeit ein angenehmes
              und sicheres Umfeld bietet."
            />
            <Leystung
              img={Photovoltaikreinigung}
              title="Photovoltaikreinigung"
              description="Die Effizienz Ihrer Photovoltaikanlage hängt von ihrer
              Sauberkeit ab. Unsere Photovoltaikreinigungsdienste sorgen
              dafür, dass Ihre Solarpaneele von Schmutz und Ablagerungen
              befreit werden. Damit gewährleisten wir, dass Ihre Anlage
              optimale Leistung erbringt und Sie das volle Potenzial Ihrer
              erneuerbaren Energiequelle nutzen können."
            />
          </div>
        </div>
        <div className={styles.why__section}>
          <h1>Warum Ipekten Dienstleistungen?</h1>
          <div className={styles.reason}>
            <h3>Professionelles Team</h3>
            <span>
              Unsere hochqualifizierten Fachkräfte stehen für höchste
              Reinigungsstandards.
            </span>
          </div>
          <div className={styles.reason}>
            <h3>Modernste Ausrüstung</h3>
            <span>
              Wir nutzen innovative Technologien und Ausrüstungen für effiziente
              Reinigungslösungen.
            </span>
          </div>
          <div className={styles.reason}>
            <h3>Kundenzufriedenheit</h3>
            <span>
              Ihre Zufriedenheit ist unser oberstes Ziel. Wir streben stets nach
              Exzellenz in unseren Dienstleistungen.
            </span>
          </div>
          <div className={styles.reason}>
            <h3>Regionales Team</h3>
            <span>
              Wir berechnen Ihnen keine Anfahrtskosten im Umkreis von 20km.
            </span>
          </div>
        </div>
        <div className={styles.refspace} ref={contactRef}></div>
        <div className={styles.contact__section}>
          <span className={styles.contact__span}>
            Möchten Sie mehr darüber erfahren, wie Ipekten Dienstleistungen Ihr
            Gebäude auf Hochglanz bringen kann? Kontaktieren Sie uns für eine
            unverbindliche Beratung. Wir freuen uns darauf, Ihnen
            maßgeschneiderte Lösungen für Ihre spezifischen
            Reinigungsanforderungen anzubieten.
          </span>
          <h1>Kontaktieren Sie uns noch heute!</h1>
          <Contact />
        </div>
      </div>
    </div>
  );
}

export default Home;
