import styles from "./ContactPage.module.scss";
import Contact from "../../components/contact/Contact";
import { Helmet } from "react-helmet-async";

function ContactPage() {
  return (
    <div className={styles.ContactPage}>
      <Helmet>
        <title>Kontakt | Ipekten Dienstleistungen</title>
        <meta
          name="description"
          content="24/7 Notdienst und schnelles Handeln für Rohrverstopfungen und andere Notfälle. Jetzt Kontakt aufnehmen!"
        />
        <meta property="og:title" content="Kontakt Ipekten Dienstleistungen" />
        <meta
          property="og:description"
          content="24/7 Notdienst und schnelles Handeln für Rohrverstopfungen und andere Notfälle."
        />
        <meta property="og:url" content="https://ipekten.de/contact" />
      </Helmet>
      <div className={styles.section__container}>
        <h1>Notfall? Wir sind nur einen Anruf entfernt</h1>
        <span>
          In Situationen, die sofortiges Handeln erfordern, sind wir Ihr
          verlässlicher Partner mit unserem 24/7 Notdienst. Egal, ob es sich um
          eine akute Rohrverstopfung, einen Wasserschaden oder einen anderen
          dringenden Reinigungsbedarf handelt – wir stehen rund um die Uhr
          bereit, um Ihnen in Ihrer Notlage zu helfen. Unsere hochqualifizierten
          und erfahrenen Notdienstteams sind darauf geschult, schnell und
          effektiv auf unvorhergesehene Ereignisse zu reagieren. Wir verstehen,
          dass Notfälle nicht nach Geschäftszeiten oder an Wochenenden und
          Feiertagen auftreten. Daher sind wir stets einsatzbereit, um Ihnen in
          jeder Situation zur Seite zu stehen. (ggf. Kostenzuschlag)
        </span>
        <h2>
          <a href="tel:+4971508108672" className={styles.callLink}>
            Jetzt unter +49 7150 810 86 72 anrufen
          </a>
        </h2>
        <h1>Kontakt</h1>
        <Contact className={styles.form} />
      </div>
    </div>
  );
}

export default ContactPage;
